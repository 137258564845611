@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300..700&display=swap');
@import './styles/scrollbar.css';
@import './styles/border-grainent.css';

body {
  /* background-color: #191919; */
  font-family: "Rubik Variable", sans-serif;
}


