.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.scroll-container {
	scroll-behavior: smooth;
}

.scroll-container::-webkit-scrollbar {
	width: 15px; /* ширина для вертикального скролла */
	height: 6px; /* высота для горизонтального скролла */
}

.scroll-container-dark::-webkit-scrollbar-track {
  background: #FFFFFF40; /* Цвет дорожки */
}

/* ползунок скроллбара */

.scroll-container-dark::-webkit-scrollbar-thumb {
	background-color: #FFFFFF;
	border-radius: 20px;
	border: 6px solid #FFFFFF;
	}

.scroll-container-light::-webkit-scrollbar-track {
	background: #bdbdbd; /* Цвет дорожки */
}

.scroll-container-light::-webkit-scrollbar-thumb {
	background-color: #000000CC; /* Цвет бегунка */
  border-radius: 20px; /* Округление бегунка */
  border: 3px solid #000000CC; /* Оформление границ бегунка */
	}

.scroll-container-light-bread::-webkit-scrollbar-track {
	background: rgba(160, 160, 160, 1)
}

.scroll-container-light-bread::-webkit-scrollbar-thumb {
	background: linear-gradient(to left, rgba(255, 174, 78, 1), rgba(255, 76, 140, 1)); /* Цвет бегунка */
  border-radius: 20px; /* Округление бегунка */
	}

/*
.scroll-container {
	scrollbar-color: #FFFFFF #FFFFFF40;
	scrollbar-width: thin;
}
*/
